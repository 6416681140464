import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import Booking from './pages/Booking';
import NotFound from './pages/NotFound';

import { Preloader } from './components';
import ThankYouPage from './pages/ThankYouPage';

export const ADMIN_URL = 'https://admin.wefix-appliance.co/wp-json/wp/v2';

const App = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/general_settings?slug=booking`);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Preloader />;

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/tampa" />} />

      {data[0]?.acf.cities.map((page, index) => (
        <Route path={`/${String(page.city_name).toLocaleLowerCase().split(' ').join('-')}`} key={index}>
          <Route
            index
            element={
              <Booking
                settings={page}
                services={data[0]?.acf.services}
                brands={data[0]?.acf.brands}
                faq={data[0]?.acf.faq}
              />
            }
          />
          <Route path="thank-you" element={<ThankYouPage settings={page} />} />
        </Route>
      ))}

      <Route path="/404" element={<NotFound />} />

      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};

export default App;
