import './header.scss';

const Header = ({ phone }) => (
  <header className="page-header">
    <div className="container">
      <div className="page-header__inner">
        <a href="https://wefix-appliance.com/">Visit Website</a>

        <a href={`tel:+1${phone}`}>
          <span />
          {phone}
        </a>
      </div>
    </div>
  </header>
);

export default Header;
